import { AppState as RootAppState } from '../../../providers/store/app.state';

export enum ModalCategory {
    INFO,
    CONFIRM,
    OVERLAY,
    OVERLAY_COMPONENT,
    ERROR,
    INTERRUPT_INFO,
    STOP_INFO,
    SURVEY_POPUP,
    BROWSER_DETECTION
}

export enum ModalStyle {
    DEFAULT = 'modal-default',
    DARK = 'modal-dark',
    TRANSPARENT = 'modal-transprent'
}

export interface ModalState {
    isActive: boolean;
    message: string[] | null;
    category: ModalCategory | null;
    component: any | null;
    style: ModalStyle;
}

export class ModalInitialState implements ModalState {
    isActive = false;
    message: null;
    category: null;
    component: null;
    style: ModalStyle.DEFAULT;
}

// Extend App States #######################################################################################################################
export interface AppState extends RootAppState {
    modal: ModalState;
}
