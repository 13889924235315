import { Action } from '@ngrx/store';
import { ModalCategory, ModalStyle } from './modal.state';
import { Type } from '@angular/core';
import { ModalContentComponentClass } from '../model/interface';

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_OVERLAY = 'MODAL_OVERLAY';
export const MODAL_OVERLAY_COMPONENT = 'MODAL_OVERLAY_COMPONENT';
export const MODAL_OPEN_CONFIRM_CLOSE_CONNECTION = 'MODAL_OPEN_CONFIRM_CLOSE_CONNECTION';

export interface ModalActionPayload {
    category: ModalCategory;
    message: string[];
    component: Type<ModalContentComponentClass>;
    style: ModalStyle;
}

export interface ModalAction extends Action {
    readonly type: string;
    payload: ModalActionPayload;
}

/**
 * @deprecated This schould never yous again for error logging
 */
export class ModalOpenAction implements ModalAction {
    readonly type = MODAL_OPEN;
    payload: ModalActionPayload = {category: null, message: null, component: null, style: ModalStyle.DEFAULT};
}

export class ModalCloseAction implements ModalAction {
    readonly type = MODAL_CLOSE;
    payload: { category: null; message: null; component: null; style: ModalStyle.DEFAULT };
}

export class ModalOverlayComponentAction implements ModalAction {
    readonly type = MODAL_OVERLAY_COMPONENT;
    payload: ModalActionPayload = {category: ModalCategory.OVERLAY_COMPONENT, message: null, component: null, style: ModalStyle.DEFAULT};

    constructor(component: Type<ModalContentComponentClass>, style: ModalStyle = ModalStyle.DEFAULT) {
        this.payload.component = component;
        this.payload.style = style;
    }
}

export class ModalOpenConfirmCloseConnectionAction implements ModalAction {
    readonly type = MODAL_OPEN;
    payload: ModalActionPayload = {category: ModalCategory.CONFIRM, message: null, component: null, style: ModalStyle.DEFAULT};
}

export class ModalOpenConnectionInterruptionNoticeAction implements ModalAction {
    readonly type = MODAL_OPEN;
    payload: ModalActionPayload = {category: ModalCategory.INTERRUPT_INFO, message: null, component: null, style: ModalStyle.DEFAULT};
}

export class ModalOpenConnectionStopNoticeAction implements ModalAction {
    readonly type = MODAL_OPEN;
    payload: ModalActionPayload = {category: ModalCategory.STOP_INFO, message: null, component: null, style: ModalStyle.DEFAULT};
}

export class ModalOpenSurveyAction implements ModalAction {
    readonly type = MODAL_OPEN;
    payload: ModalActionPayload = {category: ModalCategory.SURVEY_POPUP, message: null, component: null, style: ModalStyle.DEFAULT};
}

export class ModalOpenBrowserDetectionAction implements ModalAction {
    readonly type = MODAL_OPEN;
    payload: ModalActionPayload = {category: ModalCategory.BROWSER_DETECTION, message: null, component: null, style: ModalStyle.DEFAULT};
}
